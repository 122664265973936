<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import TemplateStepDetailModal from "./modals/templateStepDetail";
import CalendarApiService from "@/services/calendarService";
import { required } from "vuelidate/lib/validators";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "template",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
    TemplateStepDetailModal,
  },
  data() {
    return {
      title:
        this.$route.name == "editTemplate"
          ? "Edit template"
          : "Create template",
      state: this.$route.name == "editTemplate" ? "edit" : "create",
      isLoading: false,
      items: [
        {
          text: "templates",
          href: "/configuration/templates",
        },
        {
          text: "",
          active: true,
        },
      ],
      templateForm: {
        idPublic: this.$route.params.templateIdPublic,
        name: null,
        templateSteps: [],
      },
      templateStepForm: {
        progress: null,
        numberOfDays: null,
      },
      jobTypes: [],
      progressOptions: [],
      isTemplateFormSubmit: false,
      isTemplateStepFormSubmit: false,
      cantAddSameProgressError: false,
    };
  },
  computed: {
    orderedTemplateSteps() {
      function compare(a, b) {
        if(a.progress.order < b.progress.order)
          return -1;
        if (
          a.progress.order > b.progress.order
        )
          return 1;
        return 0;
      }

      //Splice pour cloner la liste avant de sort sinon ya des side effect error
      return this.templateForm.templateSteps.slice().sort(compare);
    },
  },
  validations: {
    templateForm: {
      name: { required },
    },
  },
  created() {
    this.isLoading = true;
    CalendarApiService.getAllJobTypes().then((response) => {
      this.jobTypes = response.data.data;
      if (this.state == "edit") {
        this.initEditTemplate();
      }

      this.isLoading = false;
    });
  },
  mounted() {},
  methods: {
    initEditTemplate() {
      this.isLoading = true;
      CalendarApiService.getTemplateDetail(this.templateForm.idPublic).then(
        (response) => {
          this.templateForm = response.data.data;
          this.isLoading = false;
        }
      );
    },
    editStep(step) {
      let progressUsed = this.templateForm.templateSteps.map(function (ts) {
        return ts.progress;
      });
      this.$bus.$emit("open-edit-template-step-dialog", {
        templateStepRef: step,
        progressAlreadyUsed: progressUsed,
      });
    },
    onCloseCreateTemplateStepDialog(templateStepForm) {
      this.templateForm.templateSteps.push(templateStepForm);
    },
    templateFormSubmit() {
      this.isTemplateFormSubmit = true;
      this.$v.templateForm.$touch();

      if (!this.$v.templateForm.$invalid) {
        this.isLoading = true;
        if (this.state == "create") {
          CalendarApiService.createTemplate(this.templateForm).then(
            (response) => {
              if (response.data.success) {
                this.$router.push({ name: "templates" });
                this.isLoading = false;
              }
            }
          );
        } else if (this.state == "edit") {
          CalendarApiService.updateTemplate(this.templateForm).then(
            (response) => {
              if (response.data.success) {
                this.$router.push({ name: "templates" });
                this.isLoading = false;
              }
            }
          );
        }
      }
    },
    deleteTemplateStep(templateStep) {
      var index = this.templateForm.templateSteps.findIndex(function (ts) {
        return ts.id == templateStep.id;
      });
      this.templateForm.templateSteps.splice(index, 1);
    },
    addNewTemplateStep() {
      let progressUsed = this.templateForm.templateSteps.map(function (ts) {
        return ts.progress;
      });
      this.$bus.$emit("open-create-template-step-dialog", progressUsed);
    },
    cancel() {
      this.$router.push({ name: "templates" });
    },
    getDaysForJobType(step, jobType) {
      var customDays = step.templateStepCustomDays;
      for (let cd of customDays) {
        if (cd.jobType.idPublic == jobType.idPublic) {
          return cd.numberOfDays;
        }
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('general.templateDetail')" />
    <div class="row" style="position: relative">
      <div class="col-12">
        <div class="card">
          <loading
            :active.sync="isLoading"
            :can-cancel="false"
            color="#f1b44c"
            :width="70"
            :height="70"
            loader="dots"
            :is-full-page="false"
          >
          </loading>
          <div class="card-body">
            <b-form @submit.prevent="templateFormSubmit">
              <b-form-group class="mb-4">
                <label>{{ $t("general.name") }}</label>
                <b-form-input
                  :class="{
                    'is-invalid':
                      isTemplateFormSubmit && $v.templateForm.name.$error,
                  }"
                  style="max-width: 450px"
                  v-model="templateForm.name"
                  :placeholder="$t('general.name')"
                ></b-form-input>
                <div
                  v-if="isTemplateFormSubmit && $v.templateForm.name.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.templateForm.name.required">{{
                    $t("general.requiredValue")
                  }}</span>
                </div>
              </b-form-group>
              <div class="row">
                <div class="col-12 mb-2" style="display: flex">
                  <h3>{{ $t("general.steps") }}</h3>
                  <b-button
                    @click="addNewTemplateStep()"
                    style="margin-left: auto; height: 38px"
                    variant="primary"
                    class=""
                    >{{ $t("general.add") }}</b-button
                  >
                </div>
              </div>

              <div class="table-responsive">
                <table class="table mb-0 table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th>{{ $t("general.progress") }}</th>
                      <th style="text-align: center">
                        {{ $t("general.default") }}
                      </th>
                      <th
                        v-for="(type, index) in jobTypes"
                        :key="index"
                        style="text-align: center"
                      >
                        {{ type.name }}
                      </th>
                      <th style="width: 100px; text-align: center">
                        {{ $t("general.action") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(step, index) in orderedTemplateSteps"
                      :key="index"
                    >
                      <td>
                        {{ step.progress.name }} |
                        <b-badge
                          v-if="
                            step.progress.backSchedulingReferenceDate != null
                          "
                          pill
                          style="background-color: #556ee6"
                        >
                          {{
                            $t(
                              "general." +
                                step.progress.backSchedulingReferenceDate.toLowerCase()
                            )
                          }}
                        </b-badge>
                      </td>
                      <td style="text-align: center">
                        {{ step.numberOfDaysDefault }}
                      </td>
                      <td
                        style="text-align: center"
                        v-for="(type, index) in jobTypes"
                        :key="index"
                      >
                        {{ getDaysForJobType(step, type) }}
                      </td>
                      <td style="text-align: center">
                        <b-dropdown
                          dropleft
                          class="card-drop"
                          size="lg"
                          variant="link"
                          toggle-class="text-decoration-none p-0"
                          no-caret
                        >
                          <template #button-content>
                            <i class="fas fa-ellipsis-h" />
                          </template>

                          <b-dropdown-item @click="editStep(step)">
                            <i class="fas fa-pencil-alt text-success mr-1"></i>
                            {{ $t("general.edit") }}
                          </b-dropdown-item>

                          <b-dropdown-item @click="deleteTemplateStep(step)">
                            <i class="fas fa-trash-alt text-danger mr-1"></i>
                            {{ $t("general.delete") }}
                          </b-dropdown-item>
                        </b-dropdown>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="pt-3">
                <b-button @click="cancel()" variant="light">{{
                  $t("general.cancel")
                }}</b-button>
                <b-button
                  v-show="state == 'create'"
                  type="submit"
                  class="ml-1"
                  variant="success"
                  >{{ $t("general.create") }}</b-button
                >
                <b-button
                  v-show="state == 'edit'"
                  type="submit"
                  class="ml-1"
                  variant="success"
                  >{{ $t("general.save") }}</b-button
                >
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
    <TemplateStepDetailModal
      @close-create-template-step-dialog="onCloseCreateTemplateStepDialog"
    />
  </Layout>
</template>
