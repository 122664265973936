
<script>
//import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import CalendarApiService from "@/services/calendarService";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    //DatePicker,
    Multiselect
  },
  data() {
    return {
      showModal: false,
      title: null,
      templateStepForm: {
        progress: null,
        numberOfDaysDefault: null,
        templateStepCustomDays: []
      },
      customDayForm: {
        jobType: null,
        numberOfDays: null
      },      
      templateStepRef: null,
      state: null,
      isTemplateStepFormSubmit: false,
      isCustomDayFormSubmit: false,
      progressOptions: [],
      progressAlreadyUsed: [],
      jobTypes: [],
      selectedJobType: null
    };
  },
  created(){
    this.$bus.$on('open-edit-template-step-dialog', this.openEditTemplateStepDialog)
    this.$bus.$on('open-create-template-step-dialog', this.openCreateTemplateStepDialog)
  },
  beforeDestroy(){
    this.$bus.$off('open-edit-template-step-dialog', this.openEditTemplateStepDialog)
    this.$bus.$off('open-create-template-step-dialog', this.openCreateTemplateStepDialog)
  },
  computed: {
    availableProgress(){
      let availableProgress = [];
      for(let progress of this.progressOptions){
        let exist = this.progressAlreadyUsed.filter(function(p){
          return p.stepIdPublic == progress.stepIdPublic
        });
        if(exist.length == 0){
          availableProgress.push(progress);
        }
      }

      function compare(a, b) {
        if (a.order < b.order)
          return -1;
        if (a.order > b.order)
          return 1;
        return 0;
      }
      
      return availableProgress.sort(compare);      
    },
    availableJobTypes(){
      let availableJobType = [];
      let jobTypesAlreadyUsed = this.templateStepForm.templateStepCustomDays.map(function(cd){
        return cd.jobType;
      });
      for(let jobType of this.jobTypes){
        let exist = jobTypesAlreadyUsed.filter(function(jt){
          return jt.idPublic == jobType.idPublic
        });
        if(exist.length == 0){
          availableJobType.push(jobType);
        }
      }

      return availableJobType;      
    }
  },
  methods: {
    openEditTemplateStepDialog: function(data){
      this.title = 'Edit template step';
      this.state = 'edit';
      this.showModal = true;
      this.progressAlreadyUsed = data.progressAlreadyUsed;
      this.setTemplateStepForm(data.templateStepRef);
      this.templateStepRef = data.templateStepRef;
      this.loadData();
    },
    openCreateTemplateStepDialog: function(progressAlreadyUsed){
      this.title = 'Create template step';
      this.state = 'create';
      this.showModal = true;
      this.progressAlreadyUsed = progressAlreadyUsed;

      this.loadData();
    },    
    setTemplateStepForm(templateStepRef){
      this.templateStepForm.progress = templateStepRef.progress;
      this.templateStepForm.numberOfDaysDefault = templateStepRef.numberOfDaysDefault;
      for(let cd of templateStepRef.templateStepCustomDays){
        let model = {
          id: cd.id,
          jobType: cd.jobType,
          numberOfDays: cd.numberOfDays
        }
        this.templateStepForm.templateStepCustomDays.push(model);
      }
    },
    setTemplateStepRef(){
      this.templateStepRef.progress = this.templateStepForm.progress;
      this.templateStepRef.numberOfDaysDefault = this.templateStepForm.numberOfDaysDefault;
      this.templateStepRef.templateStepCustomDays = [];
      for(let cd of this.templateStepForm.templateStepCustomDays){
        let model = {
          id: cd.id,
          jobType: cd.jobType,
          numberOfDays: cd.numberOfDays
        }
        this.templateStepRef.templateStepCustomDays.push(model);
      }
    },
    loadData(){
      CalendarApiService.getAllJobTypes().then((response) => {
        this.jobTypes = response.data.data;
      });
      CalendarApiService.getAllProgressListView().then((response) => {
        this.progressOptions = response.data.data;
      });
    },
    closeModal(){
      this.showModal = false;
      this.isTemplateStepFormSubmit = false;
      this.isCustomDayFormSubmit = false;
      this.progressAlreadyUsed = [];
      this.templateStepForm = {
        id: null,
        progress: null,
        numberOfDaysDefault: null,
        templateStepCustomDays: []
      };
      this.customDayForm = {
        jobType: null,
        numberOfDays: null
      };
    },
    templateStepFormSubmit(){
      this.cantAddSameProgressError = false;

      this.isTemplateStepFormSubmit = true;
      this.$v.templateStepForm.$touch()

      if (!this.$v.templateStepForm.$invalid) {
        if(this.state == "edit"){
          this.setTemplateStepRef();
        }else if(this.state == "create"){
          // return form
          this.$emit('close-create-template-step-dialog', this.templateStepForm);
        }

        this.closeModal();
      }
    },
    customDayFormSubmit(){
      this.isCustomDayFormSubmit = true;
      this.$v.customDayForm.$touch();

      if (!this.$v.customDayForm.$invalid) {
        var model = {
          id: this.customDayForm.id,
          jobType: this.customDayForm.jobType,
          numberOfDays: this.customDayForm.numberOfDays
        }
        this.templateStepForm.templateStepCustomDays.push(model);

        //reset form
        this.isCustomDayFormSubmit = false;
        this.customDayForm.jobType = null;
        this.customDayForm.numberOfDays = null;
      }      
    },
    deleteCustomDay(index){
      this.templateStepForm.templateStepCustomDays.splice(index, 1);
    }
  },
  validations: {
    templateStepForm: {
      progress: {required},
      numberOfDaysDefault: {required}
    },
    customDayForm: {
      jobType: {required},
      numberOfDays: {required}
    }
  }
};
</script>

<template>
  <b-modal
    v-model="showModal"
    :title="$t('general.templateStepDetail')"
    title-class="text-black font-18"
    hide-footer
    body-class="p-3"
  >
    <b-form @submit.prevent="templateStepFormSubmit">
      <div >
        <b-form-group style="position:relative;">
          <label>{{$t('general.progress')}}</label>
          <multiselect
            :class="{ 'is-invalid': isTemplateStepFormSubmit && $v.templateStepForm.progress.$error }"
            v-model="templateStepForm.progress"
            :options="availableProgress"
            :show-labels="false"
            label="name"
            track-by="id">
          </multiselect>
          <div v-if="isTemplateStepFormSubmit && $v.templateStepForm.progress.$error" class="invalid-feedback">
            <span v-if="!$v.templateStepForm.progress.required">{{$t('general.requiredValue')}}</span>
          </div>
        </b-form-group>
        <div class="row">
          <b-form-group style="position:relative;" class="col-6">
            <label>{{$t('general.numberOfDaysByDefault')}}</label>
            <b-form-input
              type="number"
              v-model="templateStepForm.numberOfDaysDefault"
              :class="{ 'is-invalid': isTemplateStepFormSubmit && $v.templateStepForm.numberOfDaysDefault.$error }"
              placeholder="0"
              style="width:90px; height:40px;">
            </b-form-input>
            <div v-if="isTemplateStepFormSubmit && $v.templateStepForm.numberOfDaysDefault.$error" class="invalid-feedback">
              <span v-if="!$v.templateStepForm.numberOfDaysDefault.required">{{$t('general.requiredValue')}}</span>
            </div>
          </b-form-group>
        </div>
        <div class="mt-3 mb-3">
          <h5>{{$t('general.customWorkOrderType')}}</h5>
        </div>
        <form @submit.prevent="customDayFormSubmit">
          <div class="row" style="display:flex; align-items: flex-end;">
            <div class="col-7">
              <b-form-group class="mb-0" style="position:relative; top:2px;">
                <label>{{$t('general.workOrderType')}}</label>
                <multiselect
                  style="max-height:38px; zmax-width:220px;"
                  :class="{ 'is-invalid': isCustomDayFormSubmit && $v.customDayForm.jobType.$error }"
                  v-model="customDayForm.jobType"
                  :options="availableJobTypes"
                  :show-labels="false"
                  label="name"
                  :placeholder="$t('general.selectWorkOrderTypes')"
                  track-by="id">
                </multiselect>
                <div v-if="isCustomDayFormSubmit && $v.customDayForm.jobType.$error" class="invalid-tooltip">
                  <span v-if="!$v.customDayForm.jobType.required">{{$t('general.requiredValue')}}</span>
                </div>
              </b-form-group>
            </div>
            <div class="col">
              <b-form-group class="mb-0 form-group" style="position:relative;" >
                <label>{{$t('general.days')}}</label>
                <b-form-input
                  type="number"
                  v-model="customDayForm.numberOfDays"
                  :class="{ 'is-invalid': isCustomDayFormSubmit && $v.customDayForm.numberOfDays.$error }"
                  placeholder="0"
                  style="max-width:90px; height:38px;">
                </b-form-input>
                <div v-if="isCustomDayFormSubmit && $v.customDayForm.numberOfDays.$error" class="invalid-tooltip">
                  <span v-if="!$v.customDayForm.numberOfDays.required">{{$t('general.requiredValue')}}</span>
                </div>
              </b-form-group>
            </div>
          
            <div class="col-auto">
              <b-button style="height:38px;" type="submit" class="ml-1" variant="primary" >{{$t('general.add')}}</b-button>
            </div>
          </div>
        </form>
        <div class="table-responsive mt-2">
          <table class="table mb-0">
            <thead class="thead-light">
              <tr>
                <th>{{$t('general.workOrderType')}}</th>
                <th style="text-align:center;">{{$t('general.numberOfDays')}}</th>
                <th style="text-align:center;">{{$t('general.delete')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(customDay, index) in templateStepForm.templateStepCustomDays" :key="index">
                <td style="vertical-align:middle;">{{customDay.jobType.name}}</td>
                <td style="text-align:center; vertical-align:middle;">
                  <b-form-input 
                    required
                    type="number"
                    v-model="customDay.numberOfDays"
                    placeholder="0"
                    style="max-width:90px; zheight:38px; margin:0 auto;">
                  </b-form-input>                 
                </td>
                <td style="text-align:center; color: #f46a6a; font-size:1.8em;"><i @click="deleteCustomDay(index)" style="cursor:pointer;" class="mdi mdi-trash-can-outline"></i></td>
              </tr>                                    
            </tbody>
          </table>
        </div>
      </div>
      <div class="text-right pt-3">
        <b-button variant="light" @click="closeModal">{{$t('general.close')}}</b-button>
        <b-button v-if="state == 'edit'" type="submit" class="ml-1" variant="success" >{{$t('general.ok')}}</b-button>
        <b-button v-if="state == 'create'" type="submit" class="ml-1" variant="success" >{{$t('general.add')}}</b-button>
      </div>
    </b-form>
  </b-modal>
</template>